import React, { useState, useEffect } from "react";
import { getAllApplicationsApi } from "../api/Api";

const AdminDashboard = () => {
  const [activeSection, setActiveSection] = useState("dashboard");
  const [applications, setApplications] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(false);

  useEffect(() => {
    if (activeSection === "applications") {
      fetchApplications();
    }
  }, [activeSection]);

  const fetchApplications = async () => {
    setLoading(true);
    try {
      const response = await getAllApplicationsApi();
      setApplications(response.data.data);
    } catch (err) {
      setError(err.message || "Failed to fetch applications.");
    } finally {
      setLoading(false);
    }
  };

  const renderContent = () => {
    switch (activeSection) {
      case "dashboard":
        return (
          <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
            <div className="bg-white shadow-md rounded-lg p-6">
              <h3 className="text-lg font-semibold">Total Applications</h3>
              <p className="text-2xl font-bold">{applications.length}</p>
            </div>
            <div className="bg-white shadow-md rounded-lg p-6">
              <h3 className="text-lg font-semibold">Pending Reviews</h3>
              <p className="text-2xl font-bold">10</p>
            </div>
            <div className="bg-white shadow-md rounded-lg p-6">
              <h3 className="text-lg font-semibold">Active Users</h3>
              <p className="text-2xl font-bold">5</p>
            </div>
          </div>
        );
      case "applications":
        return renderApplicationsTable();
      default:
        return <p>Section not found.</p>;
    }
  };

  const renderApplicationsTable = () => {
    if (loading) {
      return (
        <div className="flex items-center justify-center h-full">
          <p className="text-gray-600 text-lg">Loading applications...</p>
        </div>
      );
    }

    if (error) {
      return (
        <div className="flex items-center justify-center h-full">
          <p className="text-red-500 text-lg">{error}</p>
        </div>
      );
    }

    if (applications.length === 0) {
      return (
        <div className="flex items-center justify-center h-full">
          <p className="text-gray-600 text-lg">No applications found.</p>
        </div>
      );
    }

    return (
      <div className="overflow-auto bg-white shadow-md rounded-lg p-6">
        <h2 className="text-xl font-semibold text-primary mb-4">
          Applications List
        </h2>
        <table className="table-auto w-full text-left border-collapse">
          <thead className="bg-gray-100 text-gray-600 text-sm">
            <tr>
              <th className="px-4 py-2 border-b">Name</th>
              <th className="px-4 py-2 border-b">Email</th>
              <th className="px-4 py-2 border-b">Job Title</th>
              <th className="px-4 py-2 border-b">Applied At</th>
              <th className="px-4 py-2 border-b">Resume</th>
              <th className="px-4 py-2 border-b">Actions</th>
            </tr>
          </thead>
          <tbody className="text-gray-700 text-sm">
            {applications.map((application) => (
              <tr key={application._id} className="border-b hover:bg-gray-50">
                <td className="px-4 py-2">{application.name}</td>
                <td className="px-4 py-2">{application.email}</td>
                <td className="px-4 py-2">
                  {application.jobId?.title || "N/A"}
                </td>
                <td className="px-4 py-2">
                  {new Date(application.appliedAt).toLocaleDateString()}
                </td>
                <td className="px-4 py-2">
                  <a
                    href={`https://uranustechapi.onrender.com/uploads/resumes/${application.resume}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-blue-500 hover:underline"
                  >
                    View Resume
                  </a>
                </td>
                <td className="px-4 py-2">
                  <button className="text-red-500 hover:text-red-700">
                    Delete
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  };

  return (
    <div className="flex h-screen bg-gray-100">
      {/* Sidebar */}
      <aside
        className={`${
          isSidebarCollapsed ? "w-20" : "w-64"
        } bg-primary text-white flex flex-col transition-all duration-300`}
      >
        <div className="flex items-center justify-between py-4 px-6">
          <div className="flex items-center space-x-2">
            <div className="bg-white w-10 h-10 rounded-full"></div>
            {!isSidebarCollapsed && <p className="font-bold">Brand Name</p>}
          </div>
          <button
            onClick={() => setIsSidebarCollapsed(!isSidebarCollapsed)}
            className="text-white focus:outline-none"
          >
            {isSidebarCollapsed ? "Expand" : "Collapse"}
          </button>
        </div>
        <div className="py-4 px-6">
          {!isSidebarCollapsed && (
            <div className="space-y-2">
              <p className="text-sm">User Name</p>
              <p className="text-sm">user@example.com</p>
            </div>
          )}
        </div>
        <nav className="flex-1 py-4 px-2">
          <ul className="space-y-2">
            <li>
              <button
                onClick={() => setActiveSection("dashboard")}
                className={`w-full text-left px-6 py-3 rounded-lg hover:bg-primarydark ${
                  activeSection === "dashboard" && "bg-primarydark"
                }`}
              >
                Dashboard
              </button>
            </li>
            <li>
              <button
                onClick={() => setActiveSection("applications")}
                className={`w-full text-left px-6 py-3 rounded-lg hover:bg-primarydark ${
                  activeSection === "applications" && "bg-primarydark"
                }`}
              >
                Manage Applications
              </button>
            </li>
          </ul>
        </nav>
      </aside>

      {/* Main Content */}
      <div className="flex-1 p-6 overflow-y-auto">{renderContent()}</div>
    </div>
  );
};

export default AdminDashboard;
