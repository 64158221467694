export const checkTokenExpiration = () => {
    const token = localStorage.getItem("token");

    if (!token) {
        console.warn("No token found in local storage. Treating it as expired.");
        return true; // Token is considered expired
    }

    try {
        // Split the token and decode the payload
        const tokenParts = token.split(".");
        if (tokenParts.length !== 3) {
            console.error("Invalid token format.");
            return true; // Token is considered expired
        }

        const decodedPayload = JSON.parse(atob(tokenParts[1]));

        // Ensure the payload contains the `exp` field
        if (!decodedPayload.exp) {
            console.error("Token payload does not contain an expiration time.");
            return true; // Token is considered expired
        }

        // Convert expiration time to milliseconds and compare with the current time
        const expirationTime = decodedPayload.exp * 1000;
        const isExpired = Date.now() >= expirationTime;

        if (isExpired) {
            console.warn("Token has expired.");
        }

        return isExpired;
    } catch (error) {
        console.error("Error decoding or processing the token:", error);
        return true; // Token is considered expired in case of errors
    }
};
