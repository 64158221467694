import React from "react";
import logo from "../assets/logo.png";
import {
  FaFacebookF,
  FaLinkedinIn,
  FaEnvelope,
  FaPhoneAlt,
  FaMapMarkerAlt,
} from "react-icons/fa";

const Footer = () => {
  const handelSubmit = (e) => {
    e.preventDefault();
  };

  return (
    <div className="mt-10 px-4 md:px-10 lg:px-20 py-10 bg-gray-800">
      <div className="px-5 py-8">
        <div className="flex justify-between items-center">
          <div className="flex items-center">
            <img
              src={logo}
              alt="Uranus Tech Nepal"
              className="h-16 w-20 mr-4"
            />
            <h1 className="font-bold text-white text-3xl">Uranus Tech Nepal</h1>
          </div>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-4 gap-8 mt-8 text-white">
          <div>
            <h2 className="font-bold text-secondary mb-2">Services</h2>
            <ul className="space-y-1 font-light text-base">
              <li>Core Banking Solutions</li>
              <li>Web Development</li>
              <li>Mobile App Development</li>
              <li>Bulk SMS</li>
              <li>Cloud Services</li>
              <li>IT Consulting</li>
            </ul>
          </div>
          <div>
            <h2 className="font-bold text-secondary mb-2">Contact Us</h2>
            <ul className="space-y-1 font-light text-base">
              <li className="flex items-center hover:text-primary transition duration-200">
                <FaEnvelope className="mr-2" />
                <a
                  href="mailto:info@uranustechnepal.com"
                  className="hover:underline"
                  aria-label="Email"
                >
                  info@uranustechnepal.com
                </a>
              </li>
              <li className="flex items-center hover:text-primary transition duration-200">
                <FaPhoneAlt className="mr-2" />
                <a
                  href="tel:+977015244670"
                  className="hover:underline"
                  aria-label="Phone Number"
                >
                  +977-01-5244670
                </a>
              </li>
              <li className="flex items-center hover:text-primary transition duration-200">
                <FaPhoneAlt className="mr-2" />
                <a
                  href="tel:+977015244242"
                  className="hover:underline"
                  aria-label="Phone Number"
                >
                  +977-01-5244242
                </a>
              </li>
              <li className="flex items-center hover:text-primary transition duration-200">
                <FaMapMarkerAlt className="mr-2" />
                <a
                  href="https://www.google.com/maps/place/Uranus+Tech+Nepal+Pvt.+Ltd.+(%E0%A4%AF%E0%A5%81%E0%A4%B0%E0%A4%BE%E0%A4%A8%E0%A4%B8+%E0%A4%9F%E0%A5%87%E0%A4%95+%E0%A4%A8%E0%A5%87%E0%A4%AA%E0%A4%BE%E0%A4%B2+%E0%A4%AA%E0%A5%8D%E0%A4%B0%E0%A4%BE.+%E0%A4%B2%E0%A4%BF.)/data=!4m2!3m1!1s0x0:0x6a07eec117a2ff8e"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="hover:underline"
                  aria-label="Location on Google Maps"
                >
                  Nirdhan Utthan Building, Bhagawati Bahal 01, Kathmandu 44600
                </a>
              </li>
            </ul>
          </div>

          <div>
            <h2 className="font-bold text-secondary mb-2">Trusted By</h2>
            <ul className="space-y-1 font-light text-base">
              <li>NIC Asia Laghubitta</li>
              <li>NMB Laghubitta</li>
              <li>DEPROSC Laghubitta</li>
              <li>Forward MicroFinance</li>
              <li>Mahuli Laghubitta</li>
              <li>SKBBL</li>
              <li>Swabalamban Laghubitta</li>
              <li>Nepal Rastra Bank</li>
              <li>Embassy of India, Kathmandu</li>
            </ul>
          </div>
          <div>
            <h2 className="font-bold text-secondary mb-4 text-lg">
              Do You Have Any Queries?
            </h2>
            <form className="space-y-4 w-full" onSubmit={handelSubmit}>
              <input
                type="text"
                placeholder="Enter your Full Name"
                className="w-full p-3 rounded-lg text-sm text-primary border border-transparent focus:border-white focus:ring-0"
              />
              <input
                type="email"
                placeholder="Enter Your Email"
                className="w-full p-3 rounded-lg text-sm text-primary border border-transparent focus:border-white focus:ring-0"
              />
              <textarea
                placeholder="Enter your message"
                className="w-full p-3 rounded-lg text-sm text-primary border border-transparent focus:border-white focus:ring-0"
                rows="4"
              ></textarea>
              <button
                type="submit"
                className="h-10 px-5 text-white bg-secondary w-full rounded-lg hover:bg-blue-900"
              >
                Submit
              </button>
            </form>
          </div>
        </div>

        <div className="flex justify-center space-x-6 mt-6">
          <a
            href="https://www.facebook.com/uranustech/"
            target="_blank"
            rel="noopener noreferrer"
            aria-label="Facebook"
          >
            <FaFacebookF className="text-xl text-secondary cursor-pointer hover:text-secondary" />
          </a>
          <a
            href="https://np.linkedin.com/company/uranustechnepal"
            target="_blank"
            rel="noopener noreferrer"
            aria-label="LinkedIn"
          >
            <FaLinkedinIn className="text-xl cursor-pointer text-secondary hover:text-secondary" />
          </a>
        </div>

        <hr className="my-10 border-secondary" />

        <div className="text-center text-white">
          <p>© 2024 Uranus Tech Nepal Pvt. Ltd. All rights reserved.</p>
        </div>
      </div>
    </div>
  );
};

export default Footer;
