import React from "react";
import logo from "../assets/logo.png";

const PrivacyPolicy = () => {
  return (
    <>
      <div className="relative container mx-auto px-4 mt-10 mb-10">
        {/* Watermark logo */}
        <div
          className="absolute inset-0 flex justify-center items-center opacity-10 z-0"
          style={{
            backgroundImage: `url(${logo})`,
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            backgroundSize: "contain",
            opacity: 0.1,
          }}
        ></div>

        {/* Title */}
        <div
          className="text-3xl font-bold text-blue-800 pt-5 text-center mb-5"
          style={{ animation: "fadeIn 2s ease-out" }}
        >
          Privacy Policy
        </div>

        {/* Subtitle */}
        <div
          className="text-xl font-semibold text-green-700 text-center mb-10"
          style={{ animation: "fadeIn 2s ease-out" }}
        >
          Please carefully review our updated Privacy Policy, which outlines how
          we handle your personal data and your rights under applicable laws.
        </div>

        {/* Content */}
        <div
          className="bg-transparent rounded-lg p-6 mb-10"
          style={{ animation: "slideUp 0.8s ease-out forwards" }}
        >
          <div id="divToPrint" className="grid grid-cols-1 gap-8">
            {/* Collection of Personal Information */}
            <div className="bg-transparent rounded-lg shadow-lg p-6">
              <h2 className="text-xl font-semibold text-gray-700 mb-2">
                Collection of Personal Information
              </h2>
              <p className="text-gray-600">
                We collect personal information you voluntarily provide during
                registration and account setup. This information may include
                your name, email address, phone number, and organization. The
                data collected is crucial to facilitate your event participation
                and ensure you receive relevant updates and resources related to
                our services.
              </p>
            </div>

            {/* Use of Personal Information */}
            <div className="bg-transparent rounded-lg shadow-lg p-6">
              <h2 className="text-xl font-semibold text-gray-700 mb-2">
                Use of Personal Information
              </h2>
              <p className="text-gray-600">
                We use the information we collect to process your registration,
                manage payments, and send you updates regarding the conference
                and related logistics. Additionally, with your consent, we may
                occasionally send you news about future events or initiatives
                that align with your interests.
              </p>
            </div>

            {/* Data Sharing */}
            <div className="bg-transparent rounded-lg shadow-lg p-6">
              <h2 className="text-xl font-semibold text-gray-700 mb-2">
                Data Sharing
              </h2>
              <p className="text-gray-600">
                We will not share your personal information with third parties
                without your explicit consent unless required by law or
                necessary to provide our services (e.g., payment processors or
                hotel partners). In such cases, we only share the minimum
                necessary data with trusted partners who maintain robust privacy
                standards.
              </p>
            </div>

            {/* Data Security */}
            <div className="bg-transparent rounded-lg shadow-lg p-6">
              <h2 className="text-xl font-semibold text-gray-700 mb-2">
                Data Security
              </h2>
              <p className="text-gray-600">
                We take commercially reasonable precautions to safeguard your
                personal information from unauthorized access, disclosure, or
                alteration. We store your information on secure servers and use
                industry-standard technical and organizational measures to
                maintain its integrity and confidentiality.
              </p>
            </div>

            {/* Your Rights */}
            <div className="bg-transparent rounded-lg shadow-lg p-6 mb-8">
              <h2 className="text-xl font-semibold text-gray-700 mb-2">
                Your Rights
              </h2>
              <p className="text-gray-600">
                You have the right to access, correct, or delete your personal
                information at any time. You may also withdraw consent for
                certain uses of your data. To exercise these rights, please
                contact us at{" "}
                <a
                  href="mailto: info@uranustechnepal.com"
                  className="text-blue-500 hover:underline"
                >
                  info@uranustechnepal.com
                </a>
                . We will promptly respond to your inquiries and take necessary
                actions to address your concerns.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PrivacyPolicy;
