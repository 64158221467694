import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import { getAllCareersApi, submitApplicationApi } from "../api/Api";
import useDocumentTitle from "../components/DocTitle";
import contactImage from "../assets/joinus.png";
import InfoFooter from "../components/InfoFooter";

const Careers = () => {
  useDocumentTitle("Careers - URANUS TECH NEPAL");

  const [careers, setCareers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedJob, setSelectedJob] = useState(null);
  const [applyingJob, setApplyingJob] = useState(null);
  const [applicationData, setApplicationData] = useState({
    name: "",
    email: "",
    resume: null,
    coverLetter: null,
  });
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    const fetchCareers = async () => {
      try {
        const response = await getAllCareersApi();
        setCareers(response.data.data);
        setLoading(false);
      } catch (err) {
        setError(err.message || "Failed to load careers.");
        setLoading(false);
      }
    };

    fetchCareers();
  }, []);

  // Open/close modals
  const openModal = (job) => setSelectedJob(job);
  const closeModal = () => setSelectedJob(null);

  const openApplyModal = (job) => setApplyingJob(job);
  const closeApplyModal = () => {
    setApplyingJob(null);
    setApplicationData({
      name: "",
      email: "",
      resume: null,
      coverLetter: null,
    });
  };

  // Form handlers
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setApplicationData((prev) => ({ ...prev, [name]: value }));
  };

  const handleFileUpload = (e) => {
    const { name } = e.target;
    setApplicationData((prev) => ({ ...prev, [name]: e.target.files[0] }));
  };

  const handleSubmitApplication = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    const formData = new FormData();
    formData.append("jobId", applyingJob._id);
    formData.append("name", applicationData.name);
    formData.append("email", applicationData.email);
    formData.append("resume", applicationData.resume);
    formData.append("coverLetter", applicationData.coverLetter);

    try {
      await submitApplicationApi(formData); // Submit the application
      alert("Application submitted successfully!");
      closeApplyModal();
    } catch (error) {
      alert("Failed to submit application. Please try again.");
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <>
      <motion.div
        className="px-4 md:px-10 lg:px-20 py-10"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1 }}
      >
        {/* Page Header */}
        <motion.h1
          className="font-bold text-primary text-4xl text-center mb-2"
          initial={{ scale: 0.8 }}
          animate={{ scale: 1 }}
          transition={{ duration: 0.5 }}
        >
          Careers at Uranus Tech Nepal
        </motion.h1>
        <motion.p
          className="text-center text-gray-700 text-lg mb-10"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.7 }}
        >
          Join our team of innovators and help us create solutions that make a
          difference. Explore our current job openings and learn more about what
          it's like to work at Uranus Tech Nepal.
        </motion.p>

        {/* Job Openings Section */}
        <motion.div
          className="mb-16"
          initial={{ opacity: 0, y: 30 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
        >
          <h2 className="font-semibold text-3xl text-primarytext text-center mb-6">
            Current Job Openings
          </h2>
          {loading ? (
            <p className="text-center text-gray-700">Loading job openings...</p>
          ) : error ? (
            <p className="text-center text-red-500">Error: {error}</p>
          ) : careers.length === 0 ? (
            <p className="text-center text-gray-700">No vacancies available.</p>
          ) : (
            <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
              {careers.map((career) => (
                <motion.div
                  key={career._id}
                  className="shadow-lg rounded-lg p-6 bg-white hover:shadow-xl transition-shadow duration-300"
                  whileHover={{ scale: 1.05 }}
                >
                  <h3 className="font-semibold text-xl text-primary mb-2">
                    {career.title}
                  </h3>
                  <p className="text-gray-700 mb-4">{career.description}</p>
                  <p className="text-gray-600 text-sm mb-4">
                    <strong>Location:</strong> {career.location}
                  </p>
                  <button
                    onClick={() => openModal(career)}
                    className="bg-gray-200 text-gray-700 px-4 py-2 rounded hover:bg-gray-300 transition-colors duration-300 mr-4"
                  >
                    View Details
                  </button>
                  <button
                    onClick={() => openApplyModal(career)}
                    className="bg-primary text-white px-4 py-2 rounded hover:bg-primarydark transition-colors duration-300"
                  >
                    Apply Now
                  </button>
                </motion.div>
              ))}
            </div>
          )}
        </motion.div>

        {/* Join Us Section */}
        <motion.div
          className="flex flex-col md:flex-row items-center md:justify-between text-center md:text-left bg-gray-100 rounded-lg p-6"
          initial={{ opacity: 0, y: 30 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
        >
          <div className="md:w-1/2 mb-8 md:mb-0">
            <h2 className="font-semibold text-3xl text-primarytext mb-4">
              Ready to Join Us?
            </h2>
            <p className="text-gray-700 text-lg mb-8">
              If you're passionate about technology and innovation, and you want
              to make a real impact, we want to hear from you. Explore our open
              positions or get in touch with us to learn more about life at
              Uranus Tech Nepal.
            </p>
            <button className="bg-primary text-white px-6 py-3 rounded">
              Contact Us
            </button>
          </div>
          <div className="md:w-1/3">
            <img
              src={contactImage}
              alt="Contact Us"
              className="w-full h-auto"
            />
          </div>
        </motion.div>
      </motion.div>

      {/* Modal for View Details */}
      {selectedJob && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 overflow-auto">
          <div className="bg-white rounded-lg shadow-lg max-w-screen-md w-full p-6 relative mx-4 md:mx-0 overflow-y-auto max-h-[90vh]">
            {/* Close Button */}
            <button
              onClick={closeModal}
              className="absolute top-4 right-4 text-gray-700 hover:text-gray-900 text-2xl focus:outline-none"
              aria-label="Close"
            >
              &times;
            </button>

            {/* Job Title */}
            <h2 className="font-bold text-2xl text-primary mb-4 text-center">
              {selectedJob.title}
            </h2>

            {/* Job Description */}
            <p className="text-gray-700 mb-4 text-justify">
              {selectedJob.description}
            </p>

            {/* Responsibilities */}
            <h3 className="font-semibold text-lg mb-2">Responsibilities:</h3>
            <ul className="list-disc list-inside mb-4">
              {selectedJob.responsibilities.map((item, index) => (
                <li key={index} className="text-gray-700">
                  {item}
                </li>
              ))}
            </ul>

            {/* Requirements */}
            <h3 className="font-semibold text-lg mb-2">Requirements:</h3>
            <div className="mb-4">
              <p className="font-semibold">Technical Skills:</p>
              <ul className="list-disc list-inside mb-2">
                {selectedJob.requirements.technicalSkills.map(
                  (skill, index) => (
                    <li key={index} className="text-gray-700">
                      {skill}
                    </li>
                  )
                )}
              </ul>
            </div>
            {selectedJob.requirements.educationalRequirements && (
              <div className="mb-4">
                <p className="font-semibold">Educational Requirements:</p>
                <ul className="list-disc list-inside">
                  {selectedJob.requirements.educationalRequirements.map(
                    (requirement, index) => (
                      <li key={index} className="text-gray-700">
                        {requirement}
                      </li>
                    )
                  )}
                </ul>
              </div>
            )}
            {selectedJob.requirements.otherRequirements && (
              <div className="mb-4">
                <p className="font-semibold">Other Requirements:</p>
                <ul className="list-disc list-inside">
                  {selectedJob.requirements.otherRequirements.map(
                    (requirement, index) => (
                      <li key={index} className="text-gray-700">
                        {requirement}
                      </li>
                    )
                  )}
                </ul>
              </div>
            )}

            {/* Benefits */}
            {selectedJob.benefits && (
              <div className="mb-4">
                <p className="font-semibold">Benefits:</p>
                <ul className="list-disc list-inside">
                  {selectedJob.benefits.map((benefit, index) => (
                    <li key={index} className="text-gray-700">
                      {benefit}
                    </li>
                  ))}
                </ul>
              </div>
            )}

            {/* Salary */}
            <div className="mb-4">
              <p className="font-semibold">Salary:</p>
              <p className="text-gray-700">
                {selectedJob.salary || "Not specified"}
              </p>
            </div>

            {/* Application Instructions */}
            {selectedJob.applicationInstructions && (
              <div className="mb-4">
                <p className="font-semibold">Application Instructions:</p>
                <ul className="list-disc list-inside">
                  {selectedJob.applicationInstructions.map(
                    (instruction, index) => (
                      <li key={index} className="text-gray-700">
                        {instruction}
                      </li>
                    )
                  )}
                </ul>
              </div>
            )}

            {/* Application Deadline */}
            <div className="mb-4">
              <p className="font-semibold">Application Deadline:</p>
              <p className="text-gray-700">
                {new Date(selectedJob.applicationDeadline).toLocaleDateString()}
              </p>
            </div>
          </div>
        </div>
      )}

      {/* Modal for Apply Now */}
      {applyingJob && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white rounded-lg shadow-lg max-w-lg w-full p-6 relative">
            <button
              onClick={closeApplyModal}
              className="absolute top-2 right-2 text-gray-700 hover:text-gray-900 text-2xl"
            >
              &times;
            </button>
            <h2 className="font-bold text-2xl text-primary mb-4">
              Apply for {applyingJob.title}
            </h2>
            <form onSubmit={handleSubmitApplication}>
              <div className="mb-4">
                <label className="block text-gray-700 font-medium mb-2">
                  Full Name
                </label>
                <input
                  type="text"
                  name="name"
                  value={applicationData.name}
                  onChange={handleInputChange}
                  required
                  className="w-full px-3 py-2 border rounded"
                />
              </div>
              <div className="mb-4">
                <label className="block text-gray-700 font-medium mb-2">
                  Email Address
                </label>
                <input
                  type="email"
                  name="email"
                  value={applicationData.email}
                  onChange={handleInputChange}
                  required
                  className="w-full px-3 py-2 border rounded"
                />
              </div>
              <div className="mb-4">
                <label className="block text-gray-700 font-medium mb-2">
                  Resume (PDF)
                </label>
                <input
                  type="file"
                  name="resume"
                  accept=".pdf"
                  onChange={handleFileUpload}
                  required
                  className="w-full"
                />
              </div>
              <div className="mb-4">
                <label className="block text-gray-700 font-medium mb-2">
                  Cover Letter (PDF)
                </label>
                <input
                  type="file"
                  name="coverLetter"
                  accept=".pdf"
                  onChange={handleFileUpload}
                  className="w-full"
                />
              </div>
              <button
                type="submit"
                disabled={isSubmitting}
                className="bg-primary text-white px-4 py-2 rounded hover:bg-primarydark transition-colors duration-300 w-full"
              >
                {isSubmitting ? "Submitting..." : "Submit Application"}
              </button>
            </form>
          </div>
        </div>
      )}

      <InfoFooter />
    </>
  );
};

export default Careers;
