import React, { useState } from "react";
import DashboardAnimation from "../animation_component/DashboardAnimation";
import { useTypewriter } from "react-simple-typewriter";
import img1 from "../assets/27001 UKAS PNG.png";
import img2 from "../assets/certificate.png";

const Hero = () => {
  const [text] = useTypewriter({
    words: [
      "Core Banking Solution (CBS)",
      "Digital Field Application (DFA)",
      "Digital mPassbook",
      "Bulk SMS",
      "Uranus Cloud",
    ],
    loop: true,
    typeSpeed: 80,
    deleteSpeed: 50,
    delaySpeed: 1500,
  });

  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  return (
    <div className="flex flex-col lg:flex-row items-center justify-between px-4 md:px-8 lg:px-12 bg-gray-50">
      {/* Left Section */}
      <div className="lg:w-1/2 w-full text-center lg:text-left">
        <h1 className="text-primary font-bold text-base md:text-lg lg:text-xl py-2">
          Empowering Finance through Innovation
        </h1>
        <h1 className="text-black font-bold text-lg md:text-2xl lg:text-4xl">
          Our Expertise to Develop and Provide{" "}
        </h1>
        <h1 className="text-black font-bold text-lg md:text-2xl lg:text-4xl">
          Software for Banking Institutions{" "}
        </h1>
        <h1 className="text-primary font-semibold text-2xl md:text-3xl lg:text-4xl mb-4 py-2">
          {text}&nbsp;
        </h1>
        <div className="text-secondarytext font-medium text-sm md:text-base lg:text-lg mt-2 tracking-wide hidden md:block">
          <p className="text-justify">
            Revolutionising the Banking Industry via Forward-Thinking Technology
            and Cutting-Edge Information Systems, enabling organisations to make
            a bigger splash in a dynamic financial environment.
          </p>
        </div>
        {/* Buttons Section */}
        <div className="flex flex-col sm:flex-row sm:space-x-4 items-center justify-center lg:justify-start mt-6 space-y-4 sm:space-y-0">
          <button className="w-full sm:w-auto text-white font-semibold bg-primary px-6 py-3 transform transition-transform duration-300 hover:scale-105 rounded-full">
            Learn More
          </button>
          <button className="w-full sm:w-auto text-primary font-semibold border-primary border-2 px-6 py-3 transform transition-transform duration-300 hover:scale-105 hover:bg-primary hover:text-white rounded-full">
            Contact Us
          </button>
        </div>
        {/* Certification Section */}
        <h1
          className="font-bold text-secondarytext mt-6 text-lg md:text-xl cursor-pointer relative group hover:text-primary"
          onClick={openModal}
        >
          ISO 27001:2022 Certified Company
          <span className="absolute left-1 transform -translate-x-1 translate-y-1 w-max bg-gray-700 text-white text-sm rounded-lg py-1 px-3 opacity-0 group-hover:opacity-100 transition-opacity duration-300">
            View Certification
          </span>
        </h1>
      </div>

      {/* Right Section */}
      <div className="lg:w-1/2 w-full mt-6 lg:mt-0 flex items-center justify-center">
        <DashboardAnimation />
      </div>

      {/* Modal */}
      {isModalOpen && (
        <div className="fixed inset-0 bg-gray-800 bg-opacity-75 flex items-center justify-center z-50">
          <div className="bg-white rounded-lg overflow-hidden shadow-lg p-5">
            <h2 className="text-xl font-bold mb-4">
              ISO 27001:2022 Certification
            </h2>
            <div className="flex justify-center space-x-4">
              <img
                src={img1}
                alt="Certification 1"
                className="w-48 h-48 object-contain rounded-md"
              />
              <img
                src={img2}
                alt="Certification 2"
                className="w-48 h-48 object-contain rounded-md"
              />
            </div>
            <button
              className="mt-6 bg-success text-white px-4 py-2 rounded-md hover:bg-primary transition"
              onClick={closeModal}
            >
              Close
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Hero;
