import axios from "axios";

const Api = axios.create({
  // baseURL: "http://localhost:5500/api",
  baseURL: "https://uranustechapi.onrender.com/api",
  withCredentials: true,
  headers: {
    "Content-Type": "application/json",
  },
});

const getAuthHeaders = () => ({
  headers: {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  },
});

export const loginApi = (userName, password) =>
  Api.post("/user/login", { userName, password });

export const registerApi = (userData) => Api.post("/user/register", userData);

export const getUserProfileApi = (id) =>
  Api.get(`/user/${id}`, getAuthHeaders());

// -------------------- Get all Staff ---------------------//
export const getAllStaffApi = () => Api.get("/staff/getallstaff");

// -------------------- Get Feedback ---------------------//
export const getFeedbackApi = () => Api.get("/feedback/get-feedbacks");

// -------------------- Carrers ---------------------//
export const getAllCareersApi = () => Api.get("/careers");

// -------------------- Applications ---------------------//
export const submitApplicationApi = (formData) =>
  Api.post("/applications/submit", formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });

export const getAllApplicationsApi = () => {
  const token = localStorage.getItem("token");
  return Api.get("/applications", {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};



